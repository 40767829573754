'use strict';


var BRAVO = {
    steps: {
        init: function() {
            $('body').on('click','.steps button', function(){
                var $target = $($(this).data('go-to'));
                var href = $(this).data('href');

                if (href !== undefined && href.length) {
                    window.open(href);
                }

                $('.steps li').fadeOut();

                setTimeout(function(){
                    $target.fadeIn();
                }, 1000);
            });

            setTimeout(function(){
                $('.steps li').first().fadeIn();
            }, 1000);

        }
    },
    backgroundSongs: {
        init: function() {
            var player = $('#main-background-song')[0];
            player.volume = 0.1;
            player.play();
        }
    },
    flashlight: {
        init: function(){
    		$('body').mousemove(function(e){
    			$('body').css('background-position',(e.pageX - 100)+'px '+(e.pageY - 100)+'px');
    		});
        }
    },
    buttons: {
        init: function() {
            this.hoverTextEvents();
            this.runAwayEvents();
            this.finishQuestEvent();
        },
        hoverTextEvents: function() {
            $('[data-hover-text]').on('mouseover', function(){
                $(this).text($(this).data('hover-text'));
            });
            $('[data-hover-text]').on('mouseleave', function(){
               $(this).text($(this).data('text'));
            });
        },
        runAwayEvents: function() {
            $('[data-run-away]').css({position: 'relative', left: 0, top:0});

            $('[data-run-away]').on('mouseover', function() {
                var width = parseInt($(this).width());
                var leftPosition = parseInt($(this).css('left').replace('px', ''), 10);
                var newLeftPos = leftPosition - width;

                $(this).css({left: newLeftPos + 'px'});

                var soundEffect = $('#laugh')[0];
                soundEffect.play();
            })
            $('[data-run-away]').on('click', function(event) {
                event.preventDefault();
                event.stopPropagation();

                var height = parseInt($(this).height());
                var topPosition = parseInt($(this).css('top').replace('px', ''), 10);
                var newTopPos = topPosition - 2*height;

                $(this).css({top: newTopPos + 'px'});

                var soundEffect = $('#laugh')[0];
                soundEffect.play();
            })
        },
        finishQuestEvent: function(){
            $('body').on('click', '[data-finish-quest]', function(){
                $('body').html('');
            });
        }
    },
    audio: {
        init: function() {
            $('body').on('click', '[data-play-audio]', function(){
                var player = $($(this).data('play-audio'))[0];
                player.play();
            });
            $('body').on('click', '[data-pause-audio]', function() {
                var player = $($(this).data('pause-audio'))[0];
                player.pause();
            })
            $('body').on('mouseover', '[data-hover-play-audio]', function(){
                var player = $($(this).data('hover-play-audio'))[0];
                player.play();
            });
        }
    }
}

$(function(){
    BRAVO.flashlight.init();
    BRAVO.steps.init();
    BRAVO.backgroundSongs.init();
    BRAVO.buttons.init();
    BRAVO.audio.init();
});
